<template>
    <div class="main">
        <el-card class="card-box" shadow="never" v-loading="loading">
            <div slot="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>公司</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-form ref="form" :model="form" label-width="80px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="公司名稱">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="商標" required>
                            <el-upload
                                action=""
                                class="avatar-uploader"
                                :show-file-list="false"
                                v-model="form.imageUrl"
                                :http-request="postImage"
                                ref="form.imageUrl"
                            >
                                <img
                                    v-if="form.imageUrl"
                                    :src="form.imageUrl"
                                    class="avatar"
                                />
                                <i
                                    v-else
                                    class="el-icon-plus avatar-uploader-icon"
                                ></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="電話號碼">
                            <el-input v-model="form.teleNo"></el-input>
                        </el-form-item>
                        <el-form-item label="電郵地址">
                            <el-input v-model="form.email"></el-input>
                        </el-form-item>
                        <el-form-item label="地址">
                            <el-input v-model="form.address"></el-input>
                        </el-form-item>
                        <el-form-item label="公司網頁">
                            <el-input v-model="form.url"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="文件">
                            <el-upload
                                drag
                                action=""
                                multiple
                                :http-request="postFiles"
                                :before-remove="fileRemove"
                                :file-list="fileList"
                                :on-preview="filePreview"
                            >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                    將文件拖到此處，或<em>點擊上傳</em>
                                </div>
                            </el-upload>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit"
                                >提交</el-button
                            >
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
.el-card {
    width: 100% !important;
    min-width: 100%;
    overflow: initial;
}
</style>
<script>
import firebase from "firebase/compat/app";
import app from "@/firebase";

export default {
    name: "Company",
    data() {
        return {
            form: {
                name: "",
                imageUrl: "",
                url: "",
                address: "",
                teleNo: "",
                email: "",
            },
            files: [],
            loading: true,
            fileList: [],
        };
    },

    methods: {
        filePreview(f) {
           
            var a = document.createElement("a");
            a.setAttribute("a", f.name);
            a.href = f.url;
            a.click();
            a.setAttribute("target", "_blank");
            document.body.removeChild(a);
        },
        getCurrentFiles() {
            firebase
                .storage()
                .ref("handbooks")
                .listAll()
                .then((result) => result.items)
                .then((items) => {
                    items.forEach((item) => {
                        item.getDownloadURL().then((url) => {
                            this.fileList.push({
                                name: item.name,
                                url: url,
                            });
                        });
                    });
                });
        },
        fileRemove(file) {
            this.loading = true;
            firebase
                .storage()
                .ref(`handbooks/${file.name}`)
                .delete()
                .then(() => {
                    this.loading = false;
                });
        },
        handleAvatarSuccess(res, file) {
            this.form.imageUrl = URL.createObjectURL(file.raw);
        },
        postFiles(file) {
            this.loading = true;
            firebase
                .storage()
                .ref(`handbooks/${file.file.name}`)
                .put(file.file)
                .then(() => {
                    this.loading = false;
                });
        },
        postImage(file) {
            if (!file.file.type.startsWith("image/")) {
                this.$message({
                    message: `僅支援圖片`,
                    type: "error",
                });
                return;
            }
            this.form.imageUrl = URL.createObjectURL(file.file);

            const storageRef = firebase
                .storage()
                .ref(`logo/${file.file.name}`)
                .put(file.file);
            storageRef.on(
                `state_changed`,
                (snapshot) => {
                    this.uploadValue =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                    console.log(error.message);
                },
                () => {
                    this.uploadValue = 100;
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                        this.img1 = url;
                        firebase
                            .database()
                            .ref("company/info")
                            .update({
                                imageUrl: url,
                            })
                            .then(() => {});
                    });
                }
            );
        },
        onSubmit() {
            app.updateCompany(this.form).then(() => {
                this.$message({
                    message: `更新成功`,
                    type: "success",
                });
            });
        },
    },
    mounted() {
        this.getCurrentFiles();
        const infoRef = firebase.database().ref("company/info");
        infoRef
            .once("value")
            .then((snapshot) => {
                return snapshot.val();
            })
            .then((value) => {
                this.form.address = value.address;
                this.form.email = value.email;
                this.form.name = value.name;
                this.form.teleNo = value.phone;
                this.form.url = value.website;
                this.form.imageUrl = value.imageUrl;
            })
            .finally(() => {
                this.loading = false;
            });
    },
};
</script>
