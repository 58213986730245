import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyBJfjuMGUtIU4V7BhOfzvlJRFDsMRdz1eM",
  authDomain: "rising-chun-hk-trading-limited.firebaseapp.com",
  databaseURL: "https://rising-chun-hk-trading-limited-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "rising-chun-hk-trading-limited",
  storageBucket: "rising-chun-hk-trading-limited.appspot.com",
  messagingSenderId: "465191421207",
  appId: "1:465191421207:web:c79f340b77f232f1592352"
};


Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return " RISING CHUN HONG KONG TRADING LIMITED"; //公司名稱
      },
      get regFormDomain() {
        return "https://risingchunhk.xyz/"; 
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}